(function videoScript($, Drupal) {
  Drupal.behaviors.video = {
    attach(context) {
      context = context || document;

      var video = $('.video');
      $(video).each(function() {
        if ($(this).find('img.placeholder').length) {
          var placeholder = $(this).find('img.placeholder').attr('src');
          if ($(this).find('.video-embed-field-lazy img.image-style-crop-thumbnail').length) {
            $(this).find('.video-embed-field-lazy img.image-style-crop-thumbnail').attr('src', placeholder);
          }
        }
      });

      var videoItem = $(".video__item");
      var videoTrigger = $(".video__placeholder-play");

      if (!videoItem.length) {
        return;
      }

      if (!videoTrigger.length) {
        return;
      }

      var videoPlayer = $(videoItem).find('iframe');

      $(videoTrigger).click(function(e){
        e.preventDefault();
        var currentVideoPlayer =  $(this).closest('.video').find(videoPlayer);
        $(this).parent('.video__placeholder').toggle();
        $(this).parent('.video__placeholder').next(videoItem).toggleClass('active');
        $(currentVideoPlayer).attr("src", $(currentVideoPlayer).attr("src").replace("autoplay=0", "autoplay=1"));
      });

    },
  };
}(jQuery, Drupal));
